<template>
  <div
    v-if="providerTitle"
    class="welcome"
  >
    <div class="welcome-header">
      <v-row>
        <v-col class="col-12 py-0 text-center">
          <h1 class="welcome-header-title">
            Welcome, {{ providerTitle }}!
          </h1>
        </v-col>
      </v-row>
    </div>
    <div class="welcome-body text-center">
      <router-link :to="{ name: 'patientSelect' }">
        Please select client
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Welcome',
  computed: mapGetters('authentication', ['providerTitle'])
}
</script>

<style scoped lang="scss">
.welcome {
  padding-top: 92px;

  @media (max-width: 599px) {
    padding-top: 55px;
  }

  &-header {
    margin-bottom: 20px;

    &-title {
      font-size: 36px;
      font-weight: 500;

      @media (max-width: 599px) {
        font-size: 24px;
      }
    }
  }

  &-body {
    font-size: 16px;
    opacity: 0.5;
  }
}
</style>
