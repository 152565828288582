<template>
  <div>
    <div
      v-if="state"
      class="text-center loading"
    >
      <v-progress-circular
        :width="3"
        :color="color"
        indeterminate
      />
    </div>
    <slot v-else />
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    state: {
      type: Boolean,
      required: true
    },
    color: {
      type: String,
      default: "#ff9175"
    }
  }
}
</script>

<style scoped lang="scss">
.loading {
  padding: 215px 0 0 0;
}
</style>
