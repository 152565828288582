<template>
  <v-select
    :value="period.value"
    :attach="true"
    :menu-props="{
      contentClass: 'select-review-menu',
      offsetY: true
    }"
    class="v-select-review mt-2"
    :items="period.items"
    dense
    hide-details
    @change="setPeriod($event)"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('review', ['period'])
  },
  methods: {
    ...mapActions('review', ['setPeriod'])
  }
}
</script>
