<template>
  <div class="noselect">
    <Welcome v-if="!haveChosenClient" />
    <Loading
      v-else
      :state="loading"
    >
      <div class="review">
        <page-header sticky>
          <h1 class="review-header-title">
            Review results
          </h1>
        </page-header>
        <div class="review-header">
          <ReviewSections />
        </div>
        <dashboard-content hide-close-button>
          <div class="table-container">
            <div>
              <v-row>
                <v-col class="col-12 col-sm-6">
                  <SelectPeriod />
                </v-col>

                <v-col
                  v-if="completed"
                  class="col-12 col-sm-6"
                >
                  <SelectAssessment />
                </v-col>
              </v-row>
              <h4 class="assessment-subtitle">
                {{ shortName }}
              </h4>
            </div>
            <AssessmentReviewGraph
              :key="`${assessment?.name}::${days}`"
            />
            
            <div class="cta">
              <v-tooltip
                v-if="!isAdminOrHasSubscription"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ mdiInformation }}
                  </v-icon>
                </template>
                <span>Upgrade to PRO to activate printing and exporting of data</span>
              </v-tooltip>
              <v-btn
                :disabled="!isPrintDisabled"
                class="primary"
                @click="$event => print()"
              >
                Print chart
              </v-btn>
              <v-btn
                :disabled="!isPrintDisabled"
                class="primary"
                @click="exportToCSV"
              >
                Export data
              </v-btn>
            </div>
            <TableAccord
              :key="assessment?.name"
              :data="table"
              :client="client"
              :assessment="assessment"
              :is-admin-or-has-subscription="isAdminOrHasSubscription"
            />
          </div>
        </dashboard-content>
      </div>
    </Loading>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DashboardContent from '@/components/dashboard/DashboardContent'
import Loading from '@/views/dashboard/review/components/Loading'
import Welcome from '@/views/dashboard/Welcome'
import TableAccord from '@/components/review/TableAccord'
import AssessmentReviewGraph from '@/views/dashboard/review/components/AssessmentReviewGraph'
import ReviewSections from '@/views/dashboard/review/Sections'
import SelectAssessment from "@/views/dashboard/review/SelectAssessment";
import PageHeader from '@/components/dashboard/PageHeader.vue'
import { genScoreLabels } from '@/store/review/static'
import SelectPeriod from '@/views/dashboard/review/SelectPeriod'
import { mdiInformation } from '@mdi/js'
import * as htmlToImage from 'html-to-image'
import { jsPDF } from 'jspdf'
import csvDownload from 'json-to-csv-export'

export default {
  name: 'Assessment',

  components: {
    AssessmentReviewGraph,
    ReviewSections,
    DashboardContent,
    Welcome,
    Loading,
    TableAccord,
    SelectPeriod,
    SelectAssessment,
    PageHeader
  },
  data() {
    return {
      chartHasData: false,
      mdiInformation
    }
  },
  computed: {
    ...mapGetters('clients', ['client', 'haveChosenClient']),
    ...mapGetters('authentication', ['isUserLoggedIn', 'user']),
    ...mapGetters('subscription', ['hasActiveSubscription']),
    ...mapGetters('review', [
      'period', 
      'loading', 
      'from', 
      'days',
      'diffDays', 
      'table', 
      'assessment', 
      'assessShortName', 
      'assessMaxScore',
      'assessScoreStep',
      'scheduled',
      'completed',
      'reminderType',
    ]),
    isAdminOrHasSubscription() {
      return this.user.role === 'admin' || this.hasActiveSubscription
    },
    shortName() {
      if (this.completed) {
        if (this.assessShortName === 'Sleep log' || this.reminderType === 'sleeplog') {
          return 'Sleep Efficiency'
        } else {
          return `${this.assessShortName} total scores`
        }
      }

      return ''
    },
    yLabels() {
      let labels = genScoreLabels(this.assessScoreStep, this.assessMaxScore)
      return labels
    },
    isPrintDisabled() {
      return !!this.assessment && this.isAdminOrHasSubscription
    }
  },
  watch: {
    reminderType() {
      this.init()
    }
  },
  created() {
    this.unwatchIsLiveProp = this.$watch('client.id', (newVal) => {
      if (newVal) {
        this.init()
        this.unwatchIsLiveProp()
      }
    })
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions('app', ['fetchDocs']),
    ...mapActions('review', ['init']),
    ...mapMutations('review', ['set', 'reset', 'setPeriod']),
    async print() {
      const chartContainer = document.getElementById('chartContainer')
      const client = this.client.name
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'in'
      })
      htmlToImage.toPng(chartContainer, {skipFonts: true}).then(dataUrl => {
        const img = new Image()
        img.src = dataUrl
        img.crossOrigin = 'anonymous'
        
        pdf.addImage(img, 'PNG', 0.5, 1, 7.25, 2.25)
        pdf.setFillColor(255, 255, 255, 0)
        pdf.setFontSize(20)
        pdf.text(`${this.assessment.name} review chart for ${client}`, 0.5, 0.5, {
          maxWidth: 7
        })

        // this.assessment.items.forEach(item => {
        //   console.log('item', item)
        // })

        pdf.save(`${this.assessment.name} Data - ${new Date().toLocaleDateString()}.pdf`)
      })
    },
    
    async exportToCSV() {
      let data = []
      this.assessment.items.forEach(assessment => {
        data.push({
          Activity: assessment.activityTitle,
          CompletedAt: new Date(assessment.completedAt.seconds * 1000).toLocaleString(),
          Score: assessment.data.score
        })
        const csv = {
          data: data,
          filename: `${assessment.activityTitle} Data - ${new Date().toLocaleDateString()}`,
          delimiter: ',',
          headers: ['Activity', 'Date/Time Completed', 'Score']
        }
        csvDownload(csv)
      })
     
    }
  },
  head: {
    title: {
      inner: 'Review assessment',
    },
  },
}
</script>

<style scoped>
  .review-header {
    background-color: #fff;
    left: 0;
    margin: 3rem 0 0 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 6;
  }

  @media (min-width: 768px) {
    .review-header {
      left: unset;
      margin: 3rem auto;
      max-width: 1280px;
      padding-bottom: 1rem;
      width: calc(100% - 2rem)
    }
  }

  .chart-header {
    display: flex;
  }
  .select-period {
    flex: 1;
  }

  .cta {
    display: flex;
    flex: 5;
    justify-content: right;
  }

  .primary:nth-of-type(2) {
    margin-left: 1rem;
  }
  
  .table-container {
    margin-top: 3rem;
  }

  h3 {
    margin-bottom: 1rem;
  }
</style>

<style lang="scss">
@import '../../../theme/review';

.v-window {
  overflow: visible;
}

.chart-wrapper {
  max-width: 100%;
}

.cta button {
  margin-left: 1rem;
}

.review .table-container {
  max-width: 100%;
  margin: 2rem 60px 0 !important;
}

@media (min-width: 1139px) {
  .review .table-container {
    margin: 2rem auto 0;
  }
}

.review {
  align-items: center;
  display: flex;
  flex-direction: column;
}
</style>
