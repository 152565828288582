<template>
  <div
    id="reviewTableAccord"
    class="review-table-accord"
  >
    <template v-for="(item, i) in data">
      <TableAccordLine
        :key="item.startDate"
        :items="item.line"
        :panel-id="`${item.date}-${i}`"
      >
        <template #title>
          <span>{{ item.activityName == 'sleep log' ? item.date : '' }}</span>
          {{ item.title }}
        </template>
        <template #subtitle>
          {{ item.subTitle || '' }}
        </template>
        <template #right-title>
          {{ item.rightTitle }}
        </template>
        <template #right-subtitle>
          {{ item.rightSubTitle }}
        </template>
        <template
          #print
        >
          <div class="cta">
            <!-- <v-tooltip
              v-if="!isAdminOrHasSubscription"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ mdiInformation }}
                </v-icon>
              </template>
              <span>Upgrade to PRO to activate printing and exporting of data</span>
            </v-tooltip> -->
            <v-btn
              :disabled="!isAdminOrHasSubscription"
              class="primary print-table"
              @click="$event => printTable(`${item.date}-${i}`)"
            >
              Print table
            </v-btn>
          </div>
        </template>
      </TableAccordLine>
    </template>
  </div>
</template>

<script>
import TableAccordLine from './TableAccordLine'
import * as htmlToImage from 'html-to-image'
import { jsPDF } from 'jspdf'
// import { mdiInformation } from '@mdi/js'

export default {
  name: 'TableAccord',
  components: {
    TableAccordLine,
  },
  props: {
    assessment: {
      type: Object,
      default: () => {}
    },
    client: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    isAdminOrHasSubscription: {
      type: Boolean,
      default: false
    }
    
  },
  data() {
    return {
      isTableOpened: false,
      // mdiInformation
    }
  },
  computed: {
    isPrintDisabled() {
      return !!this.data
    }
  },
  created() {
    this.$on('tableOpened', open => {
      this.isTableOpened = open
    })
  },
  methods: {
    async printTable(panelId) {
      const tableContainer = document.getElementById(panelId)
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px'
      })
      const filter = (elm) => {
        const exclude = ['print-table', 'sub', 'button', 'cta']
        return !exclude.some((classname) => elm.classList?.contains(classname))
      }
      htmlToImage.toPng(tableContainer, {skipFonts: true, filter: filter}).then(dataUrl => {
        const img = new Image()
        img.src = dataUrl
        img.crossOrigin = 'anonymous'
        const imgProps = pdf.getImageProperties(img)
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
        pdf.addImage(img, 'PNG', 0, 64, pdfWidth, pdfHeight)
        pdf.setFillColor(255, 255, 255, 0)
        pdf.setFontSize(15)
        pdf.text(`${this.assessment.name} review chart for ${this.client.name}`, 24, 24, {
          maxWidth: pdfWidth - 24
        })

        // this.assessment.items.forEach(item => {
        //   console.log('item', item)
        // })

        pdf.save(`${this.assessment.name} Data - ${new Date().toLocaleDateString()}.pdf`)
      })
    },
  }
}
</script>

<style scoped>
  .cta {
    display: flex;
    flex: 5;
    flex-direction: column;
    margin: 0;
    max-width: 8rem;
  }

  sub {
    margin-top: 0.5rem;
  }

</style>
