<template>
  <div class="review-sections">
    <router-link
      :to="{ name: 'review' }"
      class="review-sections-item monitoring"
      :class="{ active: active('review') }"
    >
      <span>
        Self-monitoring and Exercises
      </span>
    </router-link>

    <router-link
      :to="{ name: 'reviewAssess' }"
      class="review-sections-item assessment"
      :class="{ active: active('reviewAssess') }"
    >
      <span>
        Assessments
      </span>
    </router-link>

    <router-link
      :to="{ name: 'reviewSleepLog' }"
      class="review-sections-item sleep-log"
      :class="{ active: active('reviewSleepLog') }"
    >
      <span>
        Sleep Log
      </span>
    </router-link>
  </div>
</template>

<script>
import Menu from '@/mixins/Menu'

export default {
  name: 'ReviewSections',
  mixins: [Menu],
  props: {
    item: {
      type: [Object, undefined],
      default: () => {}
    },
  },
  methods: {
    active(name) {
      return this.$route.name === name
    },
  },
}
</script>
<style scoped>
  .review-sections {
    margin-top: 2rem;
  }
</style>