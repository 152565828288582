<template>
  <Assessment />
</template>

<script>
import Assessment from './Assessment'

export default {
  name: 'AssessmentPage',
  components: {
    Assessment
  },
  head: {
    title: {
      inner: 'Review assessment',
    },
  },
}
</script>

<style lang="scss">
@import '../../../theme/review';
</style>
