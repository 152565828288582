<template>
  <div
    v-if="assessments.length > 1"
    id="select-assessment"
  >
    <form-select
      :value="assessment"
      :items="assessments"
      dense
      hide-details
      height="36"
      attach="#select-assessment"
      :menu-props="{
        width: '100%',
        offsetY: true,
        contentClass: 'custom-select-menu custom-select-menu--dense'
      }"
      @change="setAssess($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormSelect from '@/components/form/FormSelect'

export default {
  components: { FormSelect },
  computed: {
    ...mapGetters('review', ['assessments', 'assessment']),
    disabled() {
      return this.assessments.length <= 1
    },
  },
  methods: {
    ...mapActions('review', ['setAssess'])
  },
}
</script>

<style scoped lang="scss">
#select-assessment {
  position: relative;
}

.review {
  .v-select-review {
    border: 1px solid rgba(154, 166, 182, 0.6);
    box-sizing: border-box;
    border-radius: 15px;
    padding: 3px 15px !important;
    font-style: normal;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
  }
}
</style>
