<template>
  <v-container
    id="chartContainer"
    class="chart-wrapper"
    style="position: relative;"
  >
    <LineChartGenerator
      ref="chart"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
    <v-overlay
      v-if="chartData.dataEmpty"
      absolute
      color="#fff"
      class="overlay"
    >
      <img
        src="@/assets/nodata.svg"
        alt="No data available to display"
      >
      <p style="color: #333;">
        No data for the {{ dateRange }}
      </p>
      <v-btn
        color="primary"
        class="button"
        @click="goToLink"
      >
        Schedule {{ assessmentTypeName }}
      </v-btn>
    </v-overlay>
  </v-container>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import { mapGetters } from 'vuex'

import {
  Chart as ChartJS,
  Title,
  // Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

import {
  chartOptions
} from '@/store/review/static'

ChartJS.register(
  Title,
  // Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

const primaryBase = '#ff9175'

export default {
  name: 'AssessmentReviewGraph',
  components: {
    LineChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      rawData: []
    }
  },
  created() {
    this.computeData()
  },
  methods: {
    goToLink() {
      if (this.assessmentTypeName === "some assessments") {
        this.$router.push({path: '/dashboard/reminders/assess'})
      } else if (this.assessmentTypeName == "a sleep log from remind") {
        this.$router.push({path: '/dashboard/reminders/remind'})
      }
    },
    computeData() {
      // Multiple assessments will be grouped together

      this.rawData = this.assessChartData?.datasets?.reduce((accum, ds) => {
        ds.forEach((val, idx) => {
          // Allow for 0 scores, skip other nullish
          if (val !== 0 && !val) return

          if (Array.isArray(val)) {
            // Multiple values, same day
            val.forEach(v => {
              accum.push({
                x: this.days[idx],
                y: v
              })
            })
          } else {
            // Single value
            accum.push({
              x: this.days[idx],
              y: val
            })
          }
        })
        return accum
      }, []) ?? []
    }
  },
  computed: {
    ...mapGetters('review', [
      'noEmotions',
      'active',
      'days',
      'diffDays',
      'reminderType',
      'assessShortName',
      'assessChartData',
      'reminderType',
      'assessment',
      'assessments',
      'assessMaxScore',
      'assessScoreStep'
    ]),
    assessmentTypeName() {
      // Used to compute `Schedule ${assessmentTypeName}` on empty graph states
      if (this.reminderType === 'sleeplog') return 'a sleep log from remind'
      return 'some assessments'
    },
    dateRange() {
      return (this.days.length === 8) ? 'past week' : 'past ' + this.days.length + ' days'
    },
    isEmpty() { 
      return !!(
        this.assessment?.items?.filter(a => a?.completed).length === 0 ||   // Selected assessment may stick between filter changes
        this.assessments?.length === 0      // Total list of assessments is accurate between filter changes
      ) // undefined | boolean -> boolean
    },
    chartOptions() {

      let max = this.assessMaxScore
      let stepSize = this.assessScoreStep
      let scoreWord = 'Score'

      // sleeplog type isn't defined using the dict that provides these values
      if (this.reminderType === 'sleeplog') {
        max = 100
        stepSize = 20
        scoreWord = 'Sleep efficiency'
      }

      // Inherit defaults, adjust for different scale types
      return {
        ...chartOptions, 
        ...{
          scales: {
            y: {
              min: 0,
              max,
              ticks: {
                stepSize,
              }
            }
          },
          plugins: {
            ...chartOptions.plugins,
            tooltip: {
              ...chartOptions.plugins.tooltip,
              // https://www.chartjs.org/docs/latest/configuration/tooltip.html#tooltip-callbacks
              callbacks: {
                label(context) {
                  return `${scoreWord}: ${context.parsed.y}`
                }
              }
            },
          },
        }
      }
    },
    chartData() {
      // Data to show in the background of empty charts
      const dummyData = Array.from({length: this.days.length}, () => Math.floor(Math.random() * 10))
      const emptySet = [{
        label: 'Assessment data',
        data: dummyData
      }]

      let chartLabel = ''
      if (this.assessShortName == undefined && this.reminderType === 'sleeplog') {
        chartLabel = 'Sleep log Total Scores'
      } else {
        chartLabel = `${ this.assessShortName } Total Scores`
      }

      const dataSets = [{
        label: chartLabel,
        data: this.rawData,
        backgroundColor: primaryBase,
        borderColor: primaryBase
      }]

      return {
        dataEmpty: this.isEmpty,
        labels: this.days,
        datasets: !this.isEmpty ? dataSets : emptySet
      }
    }
  },
  watch: {
    assessChartData: {
      handler() {
        this.computeData()
      },
      deep: true
    }
  }
}
</script>
<style scoped>
>>>.chart-wrapper {
  max-width: 100%;
  margin: 0 auto;
  overflow: visible !important;
}

>>>.v-overlay__content {
  align-items: center;
  display: flex;
  flex-direction: column;
}
</style>