<template>
  <v-expansion-panels
    v-if="items"
    v-model="opened"
    flat
    accordion
    class="assessment-content"
  >
    <v-expansion-panel
      :key="`${updateTableData}`"
      class="expansion-panel "
    >
      <div :id="`${panelId}`">
        <v-expansion-panel-header
          class="pa-0"
          hide-actions
        >
          <template v-slot:default="{ open }">
            <div>
              <div class="expansion-panel-row">
                <div class="expansion-panel-column">
                  <div class="expansion-panel-header-title">
                    <slot name="title" />
                  </div>
                  <div class="expansion-panel-header-subtitle">
                    <slot name="subtitle" />
                  </div>
                </div>
              
                <div class="expansion-panel-header-end">
                  <div class="expansion-panel-column">
                    <div class="expansion-panel-header-title">
                      <slot name="right-title" />
                    </div>
                    <div class="expansion-panel-header-subtitle score-subtitle">
                      <slot name="right-subtitle" />
                    </div>
                  </div>
                  <template v-if="open">
                    <div class="button button-click" />
                  </template>
                  <template v-else>
                    <div class="button" />
                  </template>
                </div>
              </div>
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="expansion-panel-content">
          <!-- STRING RESPONSES -->
          <div
            v-for="(section, index) in stringTableData.headers"
            :key="index + '_2'"
          >
            <v-data-table
              v-if="stringTableData.items[index].length > 0"
              :headers="stringTableData.headers[index]"
              :items="stringTableData.items[index]"
              :header-props="{ sortIcon: null }"
              hide-default-footer
              disable-pagination
            />
          </div>
          <!-- SELECTION RESPONSES -->
          <div
            v-for="(section, index) in rangeTableData.headers"
            :key="index + '_0'"
          >
            <v-data-table
              v-if="rangeTableData.items[index].length > 0"
              :headers="rangeTableData.headers[index]"
              :items="rangeTableData.items[index]"
              :header-props="{ sortIcon: null }"
              hide-default-footer
              disable-pagination
              mobile-breakpoint="0"
            >
              <template 
                v-for="col, idx in rangeTableData.headers[index]" 
                v-slot:[`item.col_${idx}`]="{ item }"
              >
                <IconCheck
                  v-if="item[`col_${idx}`]"
                  :key="idx + '_0'"
                />
              </template>
            </v-data-table>
          </div>

          <!-- BOOLEAN RESPONSES -->
          <div
            v-for="(section, index) in booleanTableData.headers"
            :key="index + '_1'"  
          >
            <v-data-table
              v-if="booleanTableData.items[index].length > 0"
              :headers="booleanTableData.headers[index]"
              :items="booleanTableData.items[index]"
              :header-props="{ sortIcon: null }"
              hide-default-footer
              disable-pagination
            >
              <template
                v-for="col, idx in booleanTableData.headers[index]"
                v-slot:[`item.col_${idx}`]="{ item }"
              >
                <IconCheck 
                  v-if="item[`col_${idx}`]"
                  :key="idx + '_1'"
                />
              </template>
            </v-data-table>
          </div>
          <div class="footer-print">
            <slot name="print" />
          </div>
        </v-expansion-panel-content>
      </div>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import IconCheck from '@/components/icons/IconCheck'
import { mapMutations } from 'vuex'

export default {
  name: 'AssessmentTableLine',
  components: {
    IconCheck,
  },
  props: {
    assessmentDate: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true,
      default: () => ([])
    },
    panelId: {
      type: String,
      default: ''
    }

  },
  data() {
    return {
      opened: 1,
      overflowLength: 75,
      rangeTableData: {
        headers: [],
        items: []
      },
      booleanTableData: {
        headers: [],
        items: []
      },
      stringTableData: {
        headers: [],
        items: []
      }
    }
  },
  computed: {
    updateTableData() {
      let curr_header_range = []
      let curr_header_bool = []
      let curr_header_string = []
      let header_num_range = 0
      let header_num_bool = 0
      let header_num_string = 0

      /** General v-data-table structure
       *  headers [
       *    [{text: '', value: '', ...}, {text: '', value: '', ...}, {text: '', value: '', ...}, ...] (table 1)
       *    [{text: '', value: '', ...}, {text: '', value: '', ...}, {text: '', value: '', ...}, ...] (table 2)
       *    ...
       *  ]
       *  items [
       *    [{name: '', curr_header[idx].value: '', ...}, {name: '', curr_header[idx].value: '', ...}, ...] (table 1)
       *    [{name: '', curr_header[idx].value: '', ...}, {name: '', curr_header[idx].value: '', ...}, ...] (table 2)
       *    ...
       *  ]
       */
      return this.items.map(item => {

        // RANGE RESPONSES
        if (item.class === 'range-header') {
          curr_header_range = []
          const temp = []
          temp.push({ text: item.name, align: 'left', sortable: false, value: 'name' }) // Stub
          temp.push(...item.value.map((a, i) => ({ text: a, value: `col_${i}`, align: 'center' }))) // Columns
          this.rangeTableData.headers.push(temp)
          curr_header_range = temp
          header_num_range++                  // This is used for sectional assessments, test this with non-sectional
          this.rangeTableData.items.push([])  // New empty section
        }
        if (item.class === 'range') {
          const currRow = []
          currRow.push(item.name)      // Stub
          currRow.push(...item.value)  // Columns
          var tempObj = {}
          currRow.forEach((row, idx) => {
            idx === 0 ?                                   // If it's the first index 
            tempObj.name = row :                          // Assign the row to the 'stub' column called 'name'
            tempObj[curr_header_range[idx].value] = row   // Otherwise assign the row to the current header
          })
          this.rangeTableData.items[header_num_range - 1].push(tempObj)
        }
        
        // BOOLEAN RESPONSES
        if (item.class === 'boolean-header') {
          curr_header_bool = []
          const temp = []
          temp.push({ text: item.name, align: 'left', sortable: false, value: 'name' }) // Stub
          temp.push(...item.value.map((a, i) => ({ text: a, value: `col_${i}`, align: 'center' }))) // Columns
          this.booleanTableData.headers.push(temp)
          curr_header_bool = temp
          header_num_bool++                     // This is used for sectional assessments, test this with non-sectional
          this.booleanTableData.items.push([])  // New empty section
        }
        if (item.class === 'boolean') {
          const currRow = []
          currRow.push(item.name)      // Stub
          currRow.push(...item.value)  // Columns
          var tempObj2 = {}
          currRow.forEach((row, idx) => {
            idx === 0 ?                                   // If it's the first index 
            tempObj2['name'] = row :                      // Assign the row to the 'stub' column called 'name'
            tempObj2[curr_header_bool[idx].value] = row   // Otherwise assign the row to the current header
          })
          this.booleanTableData.items[header_num_bool - 1].push(tempObj2)
        }
        
        // STRING RESPONSES
        if (item.class === 'string-header') {
          curr_header_string = []
          const temp = []
          temp.push({ text: item.name, align: 'start', sortable: false, value: 'name' }) // Stub
          temp.push({ text: item.value, value: 'col_0' }) // Columns
          this.stringTableData.headers.push(temp)
          curr_header_string = temp
          header_num_string++                   // This is used for sectional assessments, test this with non-sectional
          this.stringTableData.items.push([])   // New empty section
        }
        if (item.class === 'string') {
          const currRow = []
          currRow.push(item.name)
          currRow.push(item.value)
          var tempObj3 = {}
          currRow.forEach((row, idx) => {
            idx === 0 ?
            tempObj3['name'] = row :
            tempObj3['col_0'] = row
          })
          this.stringTableData.items[header_num_string - 1].push(tempObj3)
        }
      })
    },
  },
  watch: {
    items: {
      handler: function() {
        this.rangeTableData.headers = [],
        this.rangeTableData.items = [],
        this.booleanTableData.headers = [],
        this.booleanTableData.items = [],
        this.stringTableData.headers = [],
        this.stringTableData.items = []
      },
      deep: true
    },
    opened(open) {
      if (undefined !== open) {
        this.$parent.$emit('tableOpened', true)
      } else {
        this.$parent.$emit('tableOpened', false)
      }
    }
    
  },
  created() {
    this.updateTableData
  },
  mounted() {

  },
  methods: {
    ...mapMutations('review', ['setAssessmentTableData']),
    isOverflow(value) {
      return value.length > this.overflowLength
    },
    overflow(value) {
      if (this.isOverflow(value)) {
        return value.substr(0, this.overflowLength) + '...'
      }

      return value
    }
  }
}
</script>

<style scoped>

  .footer-print {
    border-top: 1px solid #333;
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin: 1rem;
    padding: 1rem;
  }
  .first-half {
    float: left;
    width: 50%;
  }
  .second-half {
    float: right;
    width: 50%;
  }
  .v-data-table {
    color: #212121;
  }
  ::v-deep .v-data-table-header {
    color: #212121 !important;
    background-color: #f5f5f5;
  }
  .cta {
    margin:1rem;
  }

  .expansion-panel-column {
    padding-left: 0.325rem;
  }
  .expansion-panel-header-title {
    min-width: 3rem;
    text-align: right;
  }

  .expansion-panel-header-subtitle {
    min-width: 3rem;
  }
</style>
